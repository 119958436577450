import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
     <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              About Us
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  About Us
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="row img-twice position-relative h-100">
                <div className="col-6">
                  <img
                    className="img-fluid rounded"
                    src="img/about-1.jpg"
                    alt=""
                  />
                </div>
                <div className="col-6 align-self-end">
                  <img
                    className="img-fluid rounded"
                    src="img/about-2.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <p className="text-primary text-uppercase mb-2">// About Us</p>
                <h1 className="display-6 mb-4">
                  We Bake Every Item From The Core Of Our Hearts
                </h1>
                <p>
                  At {companyname}, we offer unparalleled solutions in freight
                  forwarding and Export that empower your expansion efforts to
                  reach every corner you aspire to. Our approach is grounded in
                  a profound understanding of both your internal and external
                  requirements, combined with a strategic outlook that presents
                  a comprehensive solution to propel your brand forward. With an
                  emphasis on delivering operational excellence, we provide
                  practical, cost-effective, and technology-driven answers to
                  the most complex logistics and supply chain management
                  challenges. Our commitment is reflected in our work, guided by
                  a progressive mindset and the ethos of "YOU TRUST US, WE
                  CARE."
                  </p><p>
                   No matter the scale of your business – whether small,
                  medium, or large – we cater to all your technological demands
                  with a contemporary approach that keeps your enterprise
                  seamlessly in motion. Our mission is to become your trusted
                  partner in navigating the intricacies of modern commerce,
                  ensuring that your business continues to thrive and evolve.
                  With {companyname}, you're not just embracing logistics
                  solutions – you're embracing a dynamic journey towards growth
                  and success.
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
