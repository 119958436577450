import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
  <div className="container-fluid top-bar bg-dark text-light px-0 wow fadeIn" data-wow-delay="0.1s">
          <div className="row gx-0 align-items-center d-none d-lg-flex">
            <div className="col-lg-6 px-5 text-start">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item"><Link className="small text-light" to="/Home">Home</Link></li>
                <li className="breadcrumb-item"><Link className="small text-light" to="/Contact">Contact</Link></li>
                <li className="breadcrumb-item"><Link className="small text-light" to="/Terms">Terms</Link></li>
                <li className="breadcrumb-item"><Link className="small text-light" to="/Privacy">Privacy</Link></li>
              </ol>
            </div>
            <div className="col-lg-6 px-5 text-end p-2">
            Contact Us: {companynumber}
            
            </div>
          </div>
        </div>
        {/* Topbar End */}
        {/* Navbar Start */}
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s"style={{top:"48px"}}>
          <Link to="/Home" className="navbar-brand ms-4 ms-lg-0">
            <img src="img/logo.png" style={{height:"100px"}}></img>
          </Link>
          <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav mx-auto p-4 p-lg-0">
              <Link to="/Home" className="nav-item nav-link active">Home</Link>
              <Link to="/About" className="nav-item nav-link">About</Link>
             
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Freight Forwarding Services</Link>
                <div className="dropdown-menu m-0">
                  <Link to="/Ocean" className="dropdown-item">Ocean Service</Link>
                  <Link to="/Air" className="dropdown-item">Air Service</Link>
                  <Link to="/Rail" className="dropdown-item">Rail Service</Link>
                  <Link to="/Surface" className="dropdown-item">Surface Service</Link>
                  <Link to="/Warehousing" className="dropdown-item">Warehouse Service</Link>
                  <Link to="/Door" className="dropdown-item">Door to Door Logistics</Link>
                
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Export Services</Link>
                <div className="dropdown-menu m-0">
                  <Link to="/Export" className="dropdown-item">Fashion</Link>
                  <Link to="/Export" className="dropdown-item">Consumer & Retail</Link>
                  <Link to="/Export" className="dropdown-item">Automotive</Link>
                  <Link to="/Export" className="dropdown-item">Technology</Link>
                  <Link to="/Export" className="dropdown-item">Energy</Link>
                  <Link to="/Export" className="dropdown-item">Healthcare</Link>
                
                </div>
              </div>
              <Link to="/Contact" className="nav-item nav-link">Contact</Link>
            </div>
            <div className=" d-none d-lg-flex">
           
            <Link to="/Getquote" className="btn btn-primary rounded-pill py-3 px-5">Request For Quote</Link>
            </div>
          </div>
        </nav>
    </>
  );
};

export default Header;
