import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Door to Door Logistics
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Door to Door Logistics
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Door to Door Logistics</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/door.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                At {companyname}, we take pride in our meticulous cargo monitoring
                process, ensuring that your shipment is under our watchful care
                from the moment we take charge of it until it's delivered to
                your doorstep. Just like nurturing an infant, we safeguard your
                cargo with the utmost care, providing you with peace of mind
                throughout the journey.
              </p>

              <p>
                Our commitment to providing a comprehensive logistics solution
                is evident through our single-window customer service division.
                {companyname} offers an all-encompassing Door-To-Door service, catering
                to various cargo needs including Full Container Loads (FCL),
                Trailer Loads, and Less than Container Loads (LCL). From the
                point of origin to the final destination, we handle every step
                of the process with the utmost care and safe delivery.
              </p>

              <p>
                Our Door-To-Door delivery services are designed to simplify
                logistics management, reduce costs, and minimize the risks
                associated with product loss or damage. By entrusting us with
                your cargo, you can focus on your core business while we take
                care of the logistics intricacies.
              </p>

              <p>
                We believe in precision and customer-centric procedures, which
                is why we develop detailed action plans to streamline the door
                delivery process. Our collaboration with representatives and
                agents of the world's leading ocean lines ensures that our door
                delivery execution is effective and reliable.
              </p>

              <p>
                With {companyname}, you'll experience seamless communication from the
                origin to the destination, involving all modes of goods movement
                for the final delivery. Our stage-wise flow of information
                provides you with real-time tracking and status updates,
                ensuring transparency and keeping you informed every step of the
                way.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
