import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Ocean Freight
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Ocean Freight
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Ocean Freight</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/ocean.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                Embark on a seamless maritime journey with our ocean freight
                expertise, where efficiency and reliability converge to create a
                smooth shipping experience. As seasoned professionals in the
                ocean freight industry, we are your trusted partners for all
                your maritime logistics needs.
              </p>

              <p>
                Our extensive knowledge of ocean freight operations enables us
                to navigate the complexities of global shipping with ease.
                Whether you're looking to transport goods across continents or
                navigate intricate trade routes, we have the expertise to ensure
                your cargo arrives at its destination safely and on schedule.
              </p>

              <p>
                At the heart of our ocean freight services lies a commitment to
                tailored solutions that cater to your specific requirements. We
                understand that each cargo is unique, and we offer a range of
                options – from full-container loads (FCL) to less-than-container
                loads (LCL) – to suit your needs. Our partnerships with leading
                carriers and shipping lines allow us to offer flexible,
                cost-effective, and reliable maritime solutions.
              </p>

              <p>
                As your dedicated ocean freight partner, we go beyond the
                transportation process. We provide end-to-end support, from
                documentation to tracking, ensuring transparency and peace of
                mind throughout the journey. With our global network and
                unwavering dedication, your cargo is in safe hands, and your
                ocean freight experience is streamlined and efficient.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
