import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
            Export Services
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Export Services
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="row  position-relative h-100">
                <div className="col-12">
                  <img
                    className="img-fluid rounded"
                    src="img/fashion.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-4">Fashion</h1>

                <p>
                  There’s no doubt fashion is an industry on the move and we are
                  right there, pacing into new markets, new cities with new
                  logistic challenges along the way. We are leading the way to
                  make things smoother for you. {companyname} has given the expedient reaction,
                  excellent quality, and convenient delivery the industry
                  demands, especially in this era of multiple sales channels and
                  web-based retailing.
                </p>
                <p>
                  Here at {companyname}, we are continually inspired to set new
                  logistics benchmarks – motivated not only by our decades of
                  experience, our know-how, and our impeccable quality
                  standards, but also by our devotion to elegant yet efficient
                  logistics solutions. Such innovation generates fresh ideas and
                  collections, strengthening not only the market position of our
                  customers but also our own.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-4">Consumer & Retail</h1>

                <p>
                  Your main goal is to create, market and offer the ideal items.
                  Ours is to handle everything else. We work with the biggest
                  FMCG manufacturers and retailers on the planet, so we
                  understand every phase of your supply chain. Our processes
                  enhance your speed to market, reduce damage to products, and
                  reinforce your relationships with the companies you rely on.
                </p>
                <p>
                  We help our customers manage increasing complexity and shorter
                  product life cycles within dynamic supply chains, while
                  successfully controlling and diminishing expenses. We empower
                  our customers to effectively and efficiently meet regular
                  demand, access new channels, bolster new product launches and
                  source globally.
                </p>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="row  position-relative h-100">
                <div className="col-12">
                  <img
                    className="img-fluid rounded"
                    src="img/consumer.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="row  position-relative h-100">
                <div className="col-12">
                  <img
                    className="img-fluid rounded"
                    src="img/automotive.webp"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-4">Automotive</h1>

                <p>
                  Today, the automotive industry stands as one of the largest
                  contributors to the global economy, yet it faces challenges
                  across various market fronts. {companyname} is well-equipped
                  with processes and services designed to uncover new avenues
                  for cost reduction and enhanced efficiency. Our solutions aim
                  to streamline processes, elevate delivery accuracy, and
                  simplify even the most intricate and expansive supply chains.
                </p>
                <p>
                  We offer proactive solutions that empower you to forge closer
                  relationships with your customers and provide the agility
                  required to swiftly respond to shifting economic landscapes.
                  With demands constantly fluctuating, tight schedules to adhere
                  to, and new products entering the scene, the demand for
                  dependable and accountable logistics providers is more
                  pronounced than ever. A concerted effort to minimize transit
                  damages is a prominent goal for the future of automobile
                  logistics.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-4">Technology</h1>

                <p>
                  The technology sector stands as a dynamic and ever-evolving
                  industry, characterized by diverse entry points and fierce
                  global competition among some of the world's most esteemed
                  brands. At every juncture, innovation and rapid market
                  penetration are propelled by discerning users. In this
                  landscape, we harness the synergy of technology, engineering,
                  and manufacturing expertise to conceive cutting-edge logistics
                  solutions, meticulously crafted to cater to the exacting
                  demands of the technology and electronics industry.
                </p>
                <p>
                  Our services encompass a spectrum of operations that underpin
                  the industry's need for swiftness and precision. Within our
                  distribution centers, we facilitate high-speed product
                  assembly and packaging endeavors. Our range of offerings
                  extends to vital tasks such as last-minute re-flashing,
                  software updates, kitting, and packaging. Seamlessly merging
                  high-volume, serialized fulfillment with a comprehensive suite
                  of non-asset-based freight management services, we orchestrate
                  a holistic solution that propels the technology industry's
                  momentum forward.
                </p>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="row  position-relative h-100">
                <div className="col-12">
                  <img
                    className="img-fluid rounded"
                    src="img/technology.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="row  position-relative h-100">
                <div className="col-12">
                  <img
                    className="img-fluid rounded"
                    src="img/energy.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-4">Energy</h1>

                <p>
                  The energy industry constitutes a comprehensive amalgamation
                  of sectors engaged in energy production and trade,
                  encompassing activities ranging from fuel extraction and
                  manufacturing to refining and distribution. In the modern era,
                  energy consumption holds immense significance, and the energy
                  industry stands as a vital cornerstone in the construction and
                  sustenance of societies worldwide.
                </p>
                <p>
                  At {companyname}, we present a holistic approach that aligns
                  with the evolution of the energy landscape. With a focus on
                  grid parity and cost efficiency, our integrated solutions
                  cater to the nuanced needs of companies operating in the
                  renewable energy domain. We recognize that organizations in
                  this field are dedicated to forging a more sustainable future,
                  and we stand as their global partner, enabling them to channel
                  their expertise towards this noble goal.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-4">Healthcare</h1>

                <p>
                  Within the realm of healthcare, the pursuit of continuous
                  improvement is not just a notion; it's a steadfast commitment
                  that resonates daily with patients and healthcare
                  professionals alike. The healthcare landscape, much like
                  various other industries, is in a state of perpetual
                  transformation, shaped by forces such as mergers,
                  consolidation, regulatory intricacies, and other external
                  factors. In this dynamic environment, forging an alliance with
                  a professional and forward-thinking supply chain partner isn't
                  just advisable—it's an imperative. The availability of
                  critical products and the unfaltering performance of the
                  supply chain stand as linchpins in upholding your unwavering
                  service commitment, both today and in the days to come.
                </p>
                <p>
                  We recognize that the healthcare domain thrives on precision
                  and innovation, demanding a supply chain that mirrors these
                  very qualities. As a partner to the healthcare sector, we
                  comprehend the profound significance of product quality and
                  the integrity of the chain of custody. Our holistic approach
                  is rooted in a profound understanding of the delicate balance
                  between precision, reliability, and the human touch that
                  characterizes healthcare.
                </p>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="row  position-relative h-100">
                <div className="col-12">
                  <img
                    className="img-fluid rounded"
                    src="img/healthcare.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
