import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Air Freight
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Air Freight
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Air Freight</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/air.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                When it comes to international air freight, look no further – we
                are the ultimate experts in delivering time-efficient logistics
                solutions. As a seasoned international freight forwarder, we
                stand as your unwavering partner, ready to address all your air
                freight requirements.
              </p>

              <p>
                Our comprehensive knowledge and experience in the realm of
                international air freight set us apart as the go-to choice for
                businesses seeking swift and efficient logistics solutions. With
                a keen understanding of the complexities that come with global
                shipments, we are committed to ensuring your cargo reaches its
                destination promptly and securely.
              </p>

              <p>
                As your dedicated air freight partner, we embrace the
                responsibility of seamlessly managing your international
                shipments. From documentation to tracking, we streamline the
                process, allowing you to focus on your core business. With a
                global network of partners and resources at our disposal, we
                guarantee a hassle-free and reliable air freight experience
                every time.
              </p>

              <p>
                Rest assured, when you choose us as your international air
                freight experts, you're opting for a reliable, efficient, and
                customer-centric approach that ensures your cargo arrives on
                time and in perfect condition. Your satisfaction is our
                priority, and we are here to provide you with the seamless
                logistics solutions you deserve.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
