import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Warehousing
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Warehousing
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Warehousing</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/warehouse.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                Discover the power of small packages with monumental impacts.
                Our Warehouse and Distribution Network Services are designed to
                revolutionize your supply chain management. Through seamlessly
                integrated pool distribution, cross-docking, warehousing, and
                transportation solutions, we provide a comprehensive approach to
                enhance your business operations. With an extensive presence
                spanning the nation, we oversee more than 15 million sq. ft. of
                warehouse space strategically positioned for optimal coverage.
              </p>

              <p>
                Our warehouse services extend beyond the ordinary, offering
                custom-tailored, integrated transportation solutions that are
                unparalleled in the industry. Within our warehouse
                infrastructure, you'll find a dynamic blend of purpose-built,
                dedicated, and multi-client facilities. Our operations are built
                on a well-structured process flow for each sub-operation,
                ensuring efficiency and precision throughout. Whether it's
                optimizing routes or tracking key metrics, our commitment to
                quality is ingrained in every facet of our service.
              </p>

              <p>
                Flexibility is a cornerstone of our Warehouse and Distribution
                Network Services. We provide both short-term and long-term
                solutions, as well as the ability to customize strategies to
                meet your unique needs. As a testament to our dedication to
                innovation, we've pioneered a cooperative delivery system that
                harnesses shared routes. This revolutionary approach drastically
                reduces transportation costs, and the resulting savings are
                directly transferred to you, our valued clients. Our growth
                story continues as we embark on developing world-class
                warehousing facilities strategically positioned in key
                industrial hubs across India.
              </p>

              <p>
                Embrace a new era of supply chain management with our Warehouse
                and Distribution Network Services. Let us optimize your
                logistics, elevate efficiency, and pave the way for unparalleled
                savings. As we expand our footprint, we remain committed to
                delivering excellence at every step, ensuring that your business
                thrives in the competitive landscape.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
