import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Surface Freight
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Surface Freight
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h1 className="mb-4">Surface Freight</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/road.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                Recognized as the industry leader, we take pride in our ability
                to meet any transportation requirement across the nation through
                our rapid, efficient, and express logistics services. Our
                reputation is built on a foundation of delivering unparalleled
                solutions that exceed expectations.
              </p>

              <p>
                Our commitment to our clients' needs drives us to provide
                exceptional surface services that are seamlessly connected
                through a dedicated fleet of trucks. Our expertise in surface
                services has positioned us as a prominent player in the field,
                ensuring the safe and secure transportation of goods from
                doorstep to destination. We prioritize transparency, and our
                advanced track & trace system empowers clients with real-time
                insights into the movement of their consignments.
              </p>

              <p>
                With an extensive fleet of containerized trucks, we are fully
                equipped to handle even the most sensitive and perishable goods.
                Our tamper-proof transit solutions are accompanied by
                hassle-free documentation processes, ensuring a smooth journey
                for your cargo. Whether your destination is around the corner or
                across the nation, our surface services guarantee reliable,
                timely, and secure deliveries.
              </p>

              <p>
                At the core of our services lies a dedication to quality,
                efficiency, and customer satisfaction. Our express logistics
                solutions are designed to transform transportation into a
                seamless experience, delivering beyond just goods but also peace
                of mind to our valued clients.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
