import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };
 


  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
{/* Footer Start */}
<div className="container-fluid bg-dark text-light footer my-6 mb-0 py-5 wow fadeIn" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6">
                <h4 className="text-light mb-4">Office Address</h4>
                <p className="mb-2"><i className="fa fa-map-marker-alt me-3" />{companyaddress}</p>
                <p className="mb-2"><i className="fa fa-phone-alt me-3" />{companynumber}</p>
                <p className="mb-2"><i className="fa fa-envelope me-3" />{companyemail}</p>
               
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className="text-light mb-4">Quick Links</h4>
                <Link className="btn btn-link" to="/Home">Home</Link>
          <Link className="btn btn-link" to="/About">About</Link>
          <Link className="btn btn-link" to="/Services">Services</Link>
          <Link className="btn btn-link" to="/Export">Export Services</Link>
          <Link className="btn btn-link" to="/Contact">Contact</Link>
          <Link className="btn btn-link" to="/Getquote">Getquote</Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className="text-light mb-4">Quick Links</h4>
                <Link to="/Ocean" className="btn btn-link">Ocean Service</Link>
                  <Link to="/Air" className="btn btn-link">Air Service</Link>
                  <Link to="/Rail" className="btn btn-link">Rail Service</Link>
                  <Link to="/Surface" className="btn btn-link">Surface Service</Link>
                  <Link to="/Warehouse" className="btn btn-link">Warehouse Service</Link>
                  <Link to="/Door" className="btn btn-link">Door to Door Logistics</Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className="text-light mb-4">{companyname}</h4>
                <div className="row g-2">
                  <p>At Optimum Enterprises, we offer unparalleled solutions in freight forwarding and Export that empower your expansion efforts to reach every corner you aspire to. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer End */}
        {/* Copyright Start */}
        <div className="container-fluid copyright text-light py-4 wow fadeIn" data-wow-delay="0.1s">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                ©2023 <Link to="/">{companyname}</Link>, All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end">
                {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
                 <Link to="/Privacy">Privacy Policy  </Link><Link to="/Terms"> Terms  </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Copyright End */}
    </>
  );
};

export default Footer;
