import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Contact from "./pages/Contact";
import Ocean from "./pages/Ocean";
import Air from "./pages/Air";
import Surface from "./pages/Surface";
import Warehousing from "./pages/Warehousing";
import Rail from "./pages/Rail";

import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Door from "./pages/Door";




import Export from "./pages/Export";


export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/Door" element={<Door />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Ocean" element={<Ocean/>} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Surface" element={<Surface />} />
          <Route path="/Rail" element={<Rail />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Warehousing" element={<Warehousing />} />
          <Route path="/Export" element={<Export />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />

        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));