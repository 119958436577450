import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Services() {
  return (
    <>
      <Header />
     <div
        className="container-fluid page-header py-6 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
            Services
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>
           
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Services
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl bg-light my-6 py-6 pt-0">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <p className="text-primary text-uppercase mb-2">// Our Services</p>
            <h1 className="display-6 mb-4">
              Explore our Freight Forwarding Services
            </h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="product-item d-flex flex-column bg-white rounded overflow-hidden h-100">
                <div className="text-center p-4">
                  <h3 className="mb-3">Ocean service</h3>
                  <span>
                    Embark on a seamless maritime journey with our ocean freight
                    expertise, where efficiency and reliability converge to
                    create a smooth shipping experience. As seasoned
                    professionals in the...
                  </span>
                </div>
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="img/ocean.jpg"
                    alt=""
                    style={{ height: "260px" }}
                  />
                  <div className="product-overlay">
                    <Link
                      className="btn btn-lg-square btn-outline-light rounded-circle"
                      to="/Ocean"
                    >
                      <i className="fa fa-eye text-primary" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="product-item d-flex flex-column bg-white rounded overflow-hidden h-100">
                <div className="text-center p-4">
                  <h3 className="mb-3">Air Service</h3>
                  <span>
                    {" "}
                    When it comes to international air freight, look no further
                    – we are the ultimate experts in delivering time-efficient
                    logistics solutions. As a seasoned international freight
                    forwarder, we..
                  </span>
                </div>
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="img/air.jpg"
                    alt=""
                    style={{ height: "260px" }}
                  />
                  <div className="product-overlay">
                    <Link
                      className="btn btn-lg-square btn-outline-light rounded-circle"
                      to="/Air"
                    >
                      <i className="fa fa-eye text-primary" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="product-item d-flex flex-column bg-white rounded overflow-hidden h-100">
                <div className="text-center p-4">
                  <h3 className="mb-3">Rail Service</h3>
                  <span>
                    {" "}
                    With a reputation for excellence in rail cargo services, our
                    company stands as a beacon of reliability and efficiency.
                    Our extensive experience in securely transporting
                    consignments via..
                  </span>
                </div>
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="img/rail.jpg"
                    alt=""
                    style={{ height: "260px" }}
                  />
                  <div className="product-overlay">
                    <Link
                      className="btn btn-lg-square btn-outline-light rounded-circle"
                      to="/Rail"
                    >
                      <i className="fa fa-eye text-primary" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="product-item d-flex flex-column bg-white rounded overflow-hidden h-100">
                <div className="text-center p-4">
                  <h3 className="mb-3">Surface Service</h3>
                  <span>
                    {" "}
                    Recognized as the industry leader, we take pride in our
                    ability to meet any transportation requirement across the
                    nation through our rapid, efficient, and express logistics
                    services. Our...
                  </span>
                </div>
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="img/road.jpg"
                    alt=""
                    style={{ height: "260px" }}
                  />
                  <div className="product-overlay">
                    <Link
                      className="btn btn-lg-square btn-outline-light rounded-circle"
                      to="/Surface"
                    >
                      <i className="fa fa-eye text-primary" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="product-item d-flex flex-column bg-white rounded overflow-hidden h-100">
                <div className="text-center p-4">
                  <h3 className="mb-3">Warehouse Service</h3>
                  <span>
                    {" "}
                    Discover the power of small packages with monumental
                    impacts. Our Warehouse and Distribution Network Services are
                    designed to revolutionize your supply chain management.
                    Through seamlessly///
                  </span>
                </div>
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="img/warehouse.jpg"
                    alt=""
                    style={{ height: "260px" }}
                  />
                  <div className="product-overlay">
                    <Link
                      className="btn btn-lg-square btn-outline-light rounded-circle"
                      to="/Warehousing"
                    >
                      <i className="fa fa-eye text-primary" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="product-item d-flex flex-column bg-white rounded overflow-hidden h-100">
                <div className="text-center p-4">
                  <h3 className="mb-3">Door to Door Logistics</h3>
                  <span>
                    {" "}
                    At {companyname}, we take pride in our meticulous cargo
                    monitoring process, ensuring that your shipment is under our
                    watchful care from the moment we take charge of it until
                    it's delivered..
                  </span>
                </div>
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="img/door.jpg"
                    alt=""
                    style={{ height: "260px" }}
                  />
                  <div className="product-overlay">
                    <Link
                      className="btn btn-lg-square btn-outline-light rounded-circle"
                      to="/Door"
                    >
                      <i className="fa fa-eye text-primary" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Product End */}

      {/* Team Start */}
      <div className="container-xxl py-6">
        <div className="container">
          <div
            className="bg-primary text-light rounded-bottom p-5 my-6 mt-0 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <div className="row g-4 align-items-center">
              <div className="col-lg-6">
                <h2 className="display-5 text-light mb-0">
                  Shipping Success, One Shipment at a Time
                </h2>
              </div>
              <div className="col-lg-6 text-lg-end">
                <div className="d-inline-flex align-items-center text-start">
                  <i className="fa fa-phone-alt fa-4x flex-shrink-0" />
                  <div className="ms-4">
                    <p className="fs-5 fw-bold mb-0">Call Us</p>
                    <p className="fs-1 fw-bold mb-0">{companynumber}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <p className="text-primary text-uppercase mb-2">// Our Services</p>
            <h1 className="display-6 mb-4">Explore Our Trade Solutions</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item text-center rounded overflow-hidden">
                <img
                  className="img-fluid"
                  src="img/fashion.jpg"
                  style={{ height: "240px" }}
                  alt=""
                />
                <div className="team-text">
                  <div className="team-title">
                    <h5>Fashion</h5>
                    <span>Export Service</span>
                  </div>
                  <div className="team-social">
                    <Link
                      to="/Export"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item text-center rounded overflow-hidden">
                <img
                  className="img-fluid"
                  src="img/consumer.jpg"
                  style={{ height: "240px" }}
                  alt=""
                />
                <div className="team-text">
                  <div className="team-title">
                    <h5>Consumer & Retail</h5>
                    <span>Export Service</span>
                  </div>
                  <div className="team-social">
                    <Link
                      to="/Export"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item text-center rounded overflow-hidden">
                <img
                  className="img-fluid"
                  src="img/automotive.webp"
                  style={{ height: "240px" }}
                  alt=""
                />
                <div className="team-text">
                  <div className="team-title">
                    <h5>Automotive</h5>
                    <span>Export Service</span>
                  </div>
                  <div className="team-social">
                    <Link
                      to="/Export"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item text-center rounded overflow-hidden">
                <img
                  className="img-fluid"
                  src="img/technology.jpg"
                  style={{ height: "240px" }}
                  alt=""
                />
                <div className="team-text">
                  <div className="team-title">
                    <h5>Technology</h5>
                    <span>Export Service</span>
                  </div>
                  <div className="team-social">
                    <Link
                      to="/Export"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item text-center rounded overflow-hidden">
                <img
                  className="img-fluid"
                  src="img/energy.jpg"
                  style={{ height: "240px" }}
                  alt=""
                />
                <div className="team-text">
                  <div className="team-title">
                    <h5>Energy</h5>
                    <span>Export Service</span>
                  </div>
                  <div className="team-social">
                    <Link
                      to="/Export"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item text-center rounded overflow-hidden">
                <img
                  className="img-fluid"
                  src="img/healthcare.jpg"
                  style={{ height: "240px" }}
                  alt=""
                />
                <div className="team-text">
                  <div className="team-title">
                    <h5>Healthcare</h5>
                    <span>Export Service</span>
                  </div>
                  <div className="team-social">
                    <Link
                      to="/Export"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      {/* Team End */}

      <Footer />
    </>
  );
}
